import update from 'immutability-helper';
import {
  TIPE_USAHA,
  SET_METODE_PEMBAYARAN,
  SET_STEP,
  GET_METODE_PEMBAYARAN,
  SET_PAYMENT_CHANNEL_SELECTED,
  SET_PAYMENT_METHOD_SELECTED,
  SET_DETAIL_BANK,
  SET_INFORMASI_BISNIS,
  GET_BIZ_TYPE,
  GET_BIZ_CATEGORY,
  GET_MERCHANT_PREVIEW,
  GET_METODE_PEMBAYARAN_WITH_SELECTED,
} from '../actions/actionType';
import { initialState } from '../initialState';

export default function (state = initialState, action) {
  switch (action.type) {
    case TIPE_USAHA:
      return update(state, {
        onboarding: {
          tipeUsaha: { $set: action.payload },
        },
      });
    case SET_STEP:
      return update(state, {
        onboarding: {
          step: { $set: action.payload },
        },
      });
    case SET_METODE_PEMBAYARAN:
      return update(state, {
        onboarding: {
          metodePembayaran: { $set: action.payload },
        },
      });
    case GET_METODE_PEMBAYARAN:
      let copyData = action.payload;
      copyData = copyData.map((item) => {
        const channel = item.payment_channels.map((data) => {
          return update(data, {
            selected: { $set: false },
          });
        });
        return update(item, {
          selected: { $set: false },
          payment_channels: { $set: channel },
        });
      });
      return update(state, {
        onboarding: {
          metodePembayaran: {
            $set: copyData,
          },
        },
      });
    case GET_METODE_PEMBAYARAN_WITH_SELECTED:
      const selectedPaymentMethod = action.payload.payment.method.split(';');
      const selectedPaymentChannel = action.payload.payment.channel.split(';');

      copyData = action.payload.data;
      copyData = copyData.map((item) => {
        const channel = item.payment_channels.map((data) => {
          if (selectedPaymentChannel.includes(data.code)) {
            return update(data, {
              selected: { $set: true },
            });
          }
          return update(data, {
            selected: { $set: false },
          });
        });
        if (selectedPaymentMethod.includes(item.code)) {
          return update(item, {
            selected: { $set: true },
            payment_channels: { $set: channel },
          });
        }
        return update(item, {
          selected: { $set: false },
          payment_channels: { $set: channel },
        });
      });
      return update(state, {
        onboarding: {
          metodePembayaran: {
            $set: copyData,
          },
        },
      });
    case SET_PAYMENT_METHOD_SELECTED:
      return update(state, {
        onboarding: {
          selectedPaymentMethod: { $set: action.payload },
        },
      });
    case SET_PAYMENT_CHANNEL_SELECTED:
      return update(state, {
        onboarding: {
          selectedPaymentChannel: { $set: action.payload },
        },
      });
    case SET_INFORMASI_BISNIS:
      return update(state, {
        onboarding: {
          informasiBisnis: { $set: action.payload },
        },
      });
    case SET_DETAIL_BANK:
      return update(state, {
        onboarding: {
          detailBank: { $set: action.payload },
        },
      });
    case GET_BIZ_TYPE:
      return update(state, {
        onboarding: {
          bizType: { $set: action.payload },
        },
      });
    case GET_BIZ_CATEGORY:
      return update(state, {
        onboarding: {
          bizCategory: { $set: action.payload },
        },
      });
    case GET_MERCHANT_PREVIEW:
      return update(state, {
        merchantPreview: { $set: action.payload },
      });
    default:
      return state;
  }
}
