import update from 'immutability-helper';
import { LOGIN, UPDATE_CLIENT, EDIT_PROFILE } from '../actions/actionType';
import { initialState } from '../initialState';

export default function (state = initialState, action) {
  switch (action.type) {
    case LOGIN:
      return update(state, {
        profile: {
          $set: action.payload,
        },
      });
    case EDIT_PROFILE:
      return update(state, {
        profile: { $set: action.payload },
      });
    case UPDATE_CLIENT:
      return update(state, {
        profile: {
          Client: { $set: action.payload },
        },
      });
    default:
      return state;
  }
}
