import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Spinner } from 'reactstrap';
// import { renderRoutes } from 'react-router-config';
import './App.scss';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import 'react-toastify/dist/ReactToastify.css';

import PublicRoute from './components/PublicRoute';
import PrivateRoute from './components/PrivateRoute';

const loading = () => (
  <div className='animated fadeIn pt-3 text-center'>
    <Spinner size='lg' color='warning'></Spinner>
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));
const Register = React.lazy(() => import('./views/Pages/Register'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));
const ForgotPassword = React.lazy(() => import('./views/Pages/ForgotPassword/ForgotPassword'));
const ValidateForgotPassword = React.lazy(() => import('./views/Pages/ForgotPassword/ValidateForgotPassword'));
const ValidateEmail = React.lazy(() => import('./views/Pages/ForgotPassword/ValidateEmail'));
// const ChangePassword = React.lazy(() => import('./views/Pages/ChangePassword/ChangePassword'));
const LandingPage = React.lazy(() => import('./views/Pages/LandingPage/LandingPage'));
const Onboarding = React.lazy(() => import('./views/Onboarding/Onboarding'));
const BerhasilBergabung = React.lazy(() => import('./views/Onboarding/BerhasilBergabung'));

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <React.Suspense fallback={loading()}>
          <Switch>
            <PublicRoute exact path='/login' name='Login Page' component={Login} />
            <PublicRoute exact path='/register' name='Register Page' component={Register} />
            <PublicRoute exact path='/forgot-password' name='Forgot Password' component={ForgotPassword} />
            <PublicRoute
              exact
              path='/forgot-password/validate'
              name='ValidateForgotPassword'
              component={ValidateForgotPassword}
            />
            <PublicRoute exact path='/email/validate' name='Validate Email' component={ValidateEmail} />
            <PrivateRoute exact path='/onboarding' name='Onboarding' component={Onboarding} />
            <PrivateRoute exact path='/berhasil-bergabung' name='Berhasil Bergabung' component={BerhasilBergabung} />
            {/* <Route
              exact
              path='/change-password'
              name='Change Password'
              render={(props) => <ChangePassword {...props} />}
            /> */}
            <PublicRoute exact path='/' name='Landing Page' component={LandingPage} />
            <Route exact path='/404' name='Page 404' render={(props) => <Page404 {...props} />} />
            <Route exact path='/500' name='Page 500' render={(props) => <Page500 {...props} />} />
            <Route path='/' name='Home' render={(props) => <DefaultLayout {...props} />} />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    );
  }
}

export default App;
