import { combineReducers } from 'redux';
import profileReducer from './profileReducer';
import onBoardingReducer from './onBoardingReducer';
import keyReducer from './keyReducer';
import userReducer from './userReducer';
import ipWhitelistReducer from './ipWhitelistReducer';
import qoinviewReducer from './qoinviewReducer';
import { USER_LOGOUT } from '../actions/actionType';

const appReducer = combineReducers({
  profile: profileReducer,
  onboarding: onBoardingReducer,
  key: keyReducer,
  user: userReducer,
  ipWhitelist: ipWhitelistReducer,
  qoinview: qoinviewReducer,
});

const rootReducer = (state, action) => {
  if (action.type === USER_LOGOUT) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
