import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { getCookie } from '../utils/cookie';

function PrivateRoute({ component: Component, ...rest }) {
  const isLogin = getCookie('token-web-merchant');
  return (
    <Route {...rest} render={(props) => (isLogin ? <Component {...props} /> : <Redirect from='/' to='/login' />)} />
  );
}

export default PrivateRoute;
