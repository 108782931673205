import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { getCookie } from '../utils/cookie';

function PublicRoute({ component: Component, ...rest }) {
  const isLogin = getCookie('token-web-merchant');
  return <Route {...rest} render={(props) => (!isLogin ? <Component {...props} /> : <Redirect to='/dashboard' />)} />;
}

export default PublicRoute;
